import React, { useEffect } from "react"
import {
  Col,
  Row,
  Typography,
  Space,
  Button,
  Card,
  Carousel,
  Empty,
  Skeleton,
  Dropdown,
  Menu,
  Pagination,
  Input,
  Select,
  Spin,
  Result,
  Segmented,
} from "antd"
import { Link, useLocation } from "react-router-dom"
import {
  UnorderedListOutlined,
  AppstoreOutlined,
  SearchOutlined,
  LoadingOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  CloseOutlined,
  DownOutlined,
  CloseCircleOutlined,
  StarFilled,
} from "@ant-design/icons"
import { useAuthSignOut } from "@react-query-firebase/auth"
import { Container } from "../components/Container"
import { Hero } from "../components/Hero"
import { MinifiedCard } from "../components/cards/MinifiedCard"
import { ProgramCard } from "../components/cards/ProgramCard"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { progressFactory } from "../helpers/progressFactory"
import { useMyLearning } from "../hooks/useMyLearning"
import { CourseLearning } from "../components/CourseLearning"
import { QuickLinks } from "../components/QuickLinks"
import db, { auth } from "../firebase"
import { useQueryClient } from "react-query"
import { fetchEnrolment } from "../network/useEnrolment"
import { useGetQueryParam } from "../hooks/useGetQueryParam"
import { useTranslation } from "react-i18next"
import { langsToTranslate } from "../constants/lang"
import { doc, getDoc, setDoc } from "firebase/firestore"

const { Title, Paragraph, Text } = Typography

const orderArr = [<SortAscendingOutlined key="asc" />, <SortDescendingOutlined key="desc" />]

function MyLearning() {
  const windowDimensions = useWindowDimensions()
  const { pathname } = useLocation()
  const carouselRef = React.useRef()
  const { state, dispatch, enrolmentsQuery } = useMyLearning()
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const queryClient = useQueryClient()
  const qPage = useGetQueryParam("page")
  const { t } = useTranslation()

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [pathname])

  const items = [
    {
      label: t("dueDate"),
      key: "Due Date",
      icon: state.sort.by === "Due Date" && orderArr[state.sort.orderIdx],
    },
    {
      label: t("enrollmentDate"),
      key: "Enrolment date",
      icon: state.sort.by === "Enrolment date" && orderArr[state.sort.orderIdx],
    },
    {
      label: t("Duration"),
      key: "Duration",
      icon: state.sort.by === "Duration" && orderArr[state.sort.orderIdx],
    },
  ]

  const menu = (
    <Menu
      style={{ flexGrow: 1, width: "100%" }}
      selectedKeys={[state.sort.by]}
      onClick={(e) =>
        dispatch({
          type: "sort",
          payload: { by: e.key, orderIdx: state.sort.orderIdx },
        })
      }
      items={items}
    />
  )

  const getTranslations = async () => {
    try {
      const snap = await getDoc(doc(db, "translations", "site"))

      let en = {}
      let fr = {}
      let es = {}
      let zh = {}

      if (snap.exists()) {
        const data = snap.data().translated

        Object.keys(data).forEach((key) => {
          en[key] = data[key].en
          fr[key] = data[key].fr
          es[key] = data[key].es
          zh[key] = data[key].zh
        })

        console.log("data::: ", {
          en,
          fr,
          es,
          zh,
        })
      }
    } catch (error) {
      console.error("Error getting document:", error)
    }
  }

  return (
    <>
      {/* <Button onClick={() =.beta> getTranslations()}>Get Translations</Button> */}
      {!enrolmentsQuery?.isLoading ? (
        <>
          {!enrolmentsQuery?.isError ? (
            <>
              {enrolmentsQuery?.data?.enrolments?.length ? (
                <>
                  <Hero autoplay>
                    {state?.enrolments?.slice(0, 5).map((learning) => (
                      <Hero.Slide key={learning.uid} img={learning.publication.featureImageUrl}>
                        {learning?.publication?.reviewScore ? (
                          <div className="review__button">
                            <StarFilled style={{ color: "#FFD700" }} />
                            <span>
                              {" "}
                              {learning?.publication?.reviewScore} {t("Rating")}
                            </span>
                            {!learning?.feedback ? (
                              <span>
                                {" "}
                                •{" "}
                                <Link to={`/${learning.publication.type}s/${learning.uid}`}>
                                  <Button style={{ margin: 0, padding: 0 }} type="link">
                                    {t("Review")}
                                  </Button>
                                </Link>
                              </span>
                            ) : null}
                          </div>
                        ) : null}
                        <Hero.Title>{learning.publication.content.title}</Hero.Title>
                        <Hero.Description>{learning.publication.content?.summary}</Hero.Description>
                        <Link to={`/${learning.publication.type}s/${learning.uid}`}>
                          {learning.publication.type === "course" && <Button type="primary">{t("goToCourse")}</Button>}
                          {learning.publication.type === "program" && (
                            <Button type="primary">{t("goToProgram")}</Button>
                          )}
                          {learning.publication.type === "external" && (
                            <Button type="primary">{t("goToExternal")}</Button>
                          )}
                          {/* <Button type="primary">Go to {learning.publication.type}</Button> */}
                        </Link>
                      </Hero.Slide>
                    ))}
                  </Hero>
                  <Container style={{ marginTop: "2.5rem", paddingBottom: "10rem" }}>
                    {/* <Button onClick={() => getTranslations()}>Get Translations</Button> */}
                    <div className="filter">
                      <Input
                        value={state?.searchToDebounce}
                        onChange={(e) =>
                          dispatch({
                            type: "field",
                            field: "searchToDebounce",
                            payload: e.target?.value,
                          })
                        }
                        className="filter__input filter__input--noMargin"
                        bordered={false}
                        placeholder={`${t("Search")}...`}
                        prefix={
                          enrolmentsQuery.isFetching ? (
                            <Spin size="small" spinning />
                          ) : (
                            <SearchOutlined indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                          )
                        }
                      />
                      <Select
                        allowClear
                        style={{ minWidth: 200 }}
                        placeholder={`${t("selectTopic")}...`}
                        className="hide--aboveMedium"
                        onChange={(value) => dispatch({ type: "topic-sort", payload: value })}
                      >
                        {state?.topics?.length &&
                          state?.topics?.map((topic, i) => (
                            <Select.Option key={`${topic}-${i}`} value={topic}>
                              {topic}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                    <Select
                      allowClear
                      style={{ minWidth: "100%", marginBottom: "3rem" }}
                      placeholder={`${t("selectTopic")}...`}
                      className="onMobile--show"
                      onChange={(value) => dispatch({ type: "topic-sort", payload: value })}
                    >
                      {state?.topics?.length &&
                        state?.topics?.map((topic, i) => (
                          <Select.Option key={`${topic}-${i}`} value={topic}>
                            {topic}
                          </Select.Option>
                        ))}
                    </Select>

                    {state?.enrolments?.filter(
                      (x) => x?.publication?.type === "course" || x?.publication?.type === "external"
                    )?.length ? (
                      <Row gutter={["32"]}>
                        <Col xs={24} lg={18}>
                          <Row style={{ marginBottom: "1rem" }} justify="space-between">
                            {state?.enrolments?.filter(
                              (x) => x?.publication?.type === "course" || x?.publication?.type === "external"
                            )?.length ? (
                              <Title style={{ margin: 0 }} level={4}>
                                {t("yourCourses")}
                              </Title>
                            ) : null}
                            <Space className="hide--aboveMedium">
                              <Space>
                                <Dropdown
                                  overlay={menu}
                                  onVisibleChange={(vis) =>
                                    dispatch({
                                      type: "field",
                                      field: "open",
                                      payload: vis,
                                    })
                                  }
                                  visible={state.open}
                                  trigger={["click"]}
                                >
                                  <Button>
                                    {state.sort.by
                                      ? `${t("sortBy")}: ${state.sort.by} (${
                                          state.sort.orderIdx === 0 ? "Asc" : "Desc"
                                        })`
                                      : `${t("sortBy")}: ${t("none")}`}{" "}
                                    <DownOutlined />
                                  </Button>
                                </Dropdown>
                                {state.sort.by && (
                                  <Button
                                    onClick={() => dispatch({ type: "clear" })}
                                    size="small"
                                    type="primary"
                                    icon={<CloseOutlined />}
                                  />
                                )}
                              </Space>
                              <Segmented
                                value={state?.view}
                                onChange={(value) =>
                                  dispatch({
                                    type: "field",
                                    field: "view",
                                    payload: value,
                                  })
                                }
                                options={[
                                  {
                                    value: "list",
                                    icon: <UnorderedListOutlined />,
                                  },
                                  {
                                    value: "grid",
                                    icon: <AppstoreOutlined />,
                                  },
                                ]}
                              />
                            </Space>
                          </Row>

                          <Row gutter="32">
                            <CourseLearning
                              view={state?.view}
                              courses={state?.enrolments?.filter(
                                (x) => x?.publication?.type === "course" || x?.publication?.type === "external"
                              )}
                            />
                            <Col span={24}>
                              <Pagination
                                pageSize={10}
                                defaultCurrent={qPage.get()}
                                total={state?.total}
                                onChange={(page) => {
                                  qPage.set(page)
                                  dispatch({ type: "page", payload: page })
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col className="hide--onMobile" xs={0} lg={6}>
                          <Title level={4}>{t("quickLinks")}</Title>
                          <QuickLinks
                            courses={state?.enrolments?.filter(
                              (x) => x?.publication?.type === "course" || x?.publication?.type === "external"
                            )}
                            programs={state?.enrolments?.filter((x) => x?.publication?.type === "program")}
                          />
                        </Col>
                      </Row>
                    ) : null}

                    {state?.enrolments?.filter((x) => x?.publication?.type === "program")?.length ? (
                      <>
                        <Title style={{ marginTop: "2.5rem" }} level={4}>
                          {t("yourPrograms")}
                        </Title>
                        <Row gutter={["16"]}>
                          <Col xs={24} lg={16}>
                            <Carousel autoplaySpeed={3000} autoplay ref={carouselRef} dots dotPosition="top">
                              {state?.enrolments
                                ?.filter((x) => x?.publication?.type === "program")
                                .map((program) => (
                                  <ProgramCard
                                    onMouseEnter={() =>
                                      queryClient.prefetchQuery(
                                        [program.uid],
                                        () => fetchEnrolment(program?.uid, queryClient),
                                        {
                                          staleTime: 1000 * 30,
                                        }
                                      )
                                    }
                                    progress={progressFactory.program(program).resultAsPercent}
                                    href={`/programs/${program.uid}`}
                                    title={program.publication.content.title}
                                    desc={program.publication.content.summary}
                                    url={program.publication.featureImageUrl}
                                    key={program.uid}
                                    enrolment={program}
                                  />
                                ))}
                            </Carousel>
                          </Col>
                          <Col xs={24} lg={8}>
                            {state?.enrolments
                              ?.filter((x) => x?.publication?.type === "program")
                              .map((program, i) => (
                                <MinifiedCard
                                  style={{ cursor: "pointer" }}
                                  onClick={() => carouselRef.current.goTo(i)}
                                  title={program.publication.content.title}
                                  meta={`${program.publication.materialCount} Sections`}
                                  url={program.publication.featureImageUrl}
                                  key={`${program.uid}-small`}
                                  program={program}
                                />
                              ))}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Container>
                </>
              ) : (
                <Result
                  style={{ marginTop: "3.5rem" }}
                  icon={<Empty description="" />}
                  title={t("youHaveNoEnrolments")}
                  subTitle={t("youArALlCaughtUp")}
                  extra={[
                    <a href="/" key="console">
                      <Button type="primary">{t("refreshPage")}</Button>
                    </a>,
                    <Link key="profile" to="/profile">
                      <Button>{t("goToProfile")}</Button>
                    </Link>,
                  ]}
                >
                  <div style={{ textAlign: "center" }} className="desc">
                    <Paragraph style={{ margin: 0 }}>
                      <CloseCircleOutlined className="site-result-demo-error-icon" /> {t("youHaveNoActiveEnrolments")}
                    </Paragraph>
                  </div>
                </Result>
              )}
            </>
          ) : (
            <Result
              style={{ marginTop: "3.5rem" }}
              status="error"
              title="Something went wrong"
              subTitle="We are unable to fetch your enrolments at the moment"
              extra={[
                <a href="/" key="console">
                  <Button type="primary">Refresh the page</Button>
                </a>,
                <Link key="profile" to="/profile">
                  <Button>Go to your profile</Button>
                </Link>,
              ]}
            >
              <div style={{ textAlign: "center" }} className="desc">
                <Paragraph>
                  <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                  {enrolmentsQuery?.error?.response?.data?.message}
                </Paragraph>
                <Paragraph>
                  <CloseCircleOutlined className="site-result-demo-error-icon" /> If this problem persists try{" "}
                  <Button
                    onClick={async () => {
                      window.localStorage.clear()
                      window.sessionStorage.clear()
                      await signOutMutation()
                    }}
                    style={{ margin: 0, padding: 0 }}
                    type="link"
                  >
                    signing out
                  </Button>
                </Paragraph>
              </div>
            </Result>
          )}
        </>
      ) : (
        <>
          <Skeleton.Input style={{ width: windowDimensions.width, height: "45vh" }} active />
          <Container style={{ marginTop: "2.5rem" }}>
            <Space size={15} style={{ marginBottom: "1.125rem" }}>
              <Skeleton.Button active />
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Space>
            <Row gutter={["32"]}>
              <Col xs={24} lg={18}>
                <Skeleton.Button size="small" style={{ margin: "1rem 0" }} />
                <Card size="small" className="respCard">
                  <Row>
                    <Col xs={24} md={8}>
                      <Skeleton.Image style={{ width: "12.5rem", height: "12.5rem" }} />
                    </Col>
                    <Col xs={24} md={13} className="content">
                      <Skeleton />
                    </Col>
                  </Row>
                </Card>
                <Card size="small" className="respCard">
                  <Row>
                    <Col xs={24} md={8}>
                      <Skeleton.Image style={{ width: "12.5rem", height: "12.5rem" }} />
                    </Col>
                    <Col xs={24} md={13} className="content">
                      <Skeleton />
                    </Col>
                  </Row>
                </Card>
                <Card size="small" className="respCard">
                  <Row>
                    <Col xs={24} md={8}>
                      <Skeleton.Image style={{ width: "12.5rem", height: "12.5rem" }} />
                    </Col>
                    <Col xs={24} md={13} className="content">
                      <Skeleton />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="hide--onMobile" xs={24} lg={6}>
                <Skeleton.Button size="small" style={{ margin: "1rem 0" }} />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  )
}

export default MyLearning
