import {
  Button,
  Card,
  Col,
  Comment,
  Divider,
  Form,
  Layout,
  message,
  Row,
  Space,
  Spin,
  Statistic,
  Tag,
  Typography,
} from "antd"
import React, { useRef, useEffect, useReducer, useMemo } from "react"
import {
  LeftOutlined,
  RightOutlined,
  LineChartOutlined,
  DeploymentUnitOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons"
import { Link, useParams } from "react-router-dom"
import { useGetPerson } from "../../../network/usePerson"
import { MultipleChoice } from "../../../components/questions/MultipleChoice"
import { TrueAndFalse } from "../../../components/questions/TrueAndFalse"
import { OrderCorrectly } from "../../../components/questions/OrderCorrectly"
import { MatchColumns } from "../../../components/questions/MatchColumns"
import { FillInMissing } from "../../../components/questions/FillInMissing"
import { Hero } from "../../../components/Hero"
import { useEnrolmentActivtyMutation } from "../../../network/useEnrolmentActivity"
import { trackAction, useLesson } from "../../../hooks/lesson-context"
import { useQueryClient } from "react-query"
import { useActivity } from "../../../network/useEnrolment"
import { AssessmentFailed } from "../../../components/AssessmentFailed"
import { AssessmentLimit } from "../../../components/AssessmentLimit"
import Countdown from "antd/lib/statistic/Countdown"
import { useQueryParam } from "../../../hooks/useQueryParam"
import { BoldText } from "../../../components/BoldText"
import { useTranslation } from "react-i18next"

const { Footer } = Layout

const defaultErrorMessage = "Please answer question"

function determineTotal(questions) {
  let total = 0
  questions?.forEach((question) => {
    switch (question?.type) {
      case "multiple-choice":
      case "true-false":
        total += question?.answers?.filter((x) => x?.correct === 1)?.length
        break
      case "order-correctly":
      case "match-columns":
      case "fill-in-missing":
        total += question?.answers?.filter((x) => x?.correct > -1)?.length
        break
      default:
        break
    }
  })
  return total
}

function determineResult(questions, submission) {
  let result = 0
  if (!questions || !submission) return 0
  questions?.forEach((question, i) => {
    const questionSub = submission[`q-${i + 1}`]

    switch (question?.type) {
      case "multiple-choice":
        if (questionSub && Array.isArray(questionSub)) {
          const parsedSubmission = questionSub?.map((sub) => JSON.parse(sub))
          result += parsedSubmission?.filter((x) => x?.correct === 1)?.length
        }
        break
      case "true-false":
        result += questionSub
        break
      case "order-correctly":
      case "match-columns":
      case "fill-in-missing":
        result += questionSub?.filter((x, index) => parseInt(x?.correct) === index)?.length
        break
      default:
        break
    }
  })

  return result
}

function determineQuestionPassed(question, submission) {
  if (!question || !submission) return 0
  switch (question?.type) {
    case "multiple-choice":
      if (submission && Array.isArray(submission)) {
        const parsedSubmission = submission?.map((sub) => JSON.parse(sub))
        const total = question?.answers?.filter((x) => x?.correct)?.length
        const answered = parsedSubmission?.filter((x) => x?.correct === 1)?.length
        if (answered === total) return true
        return false
      }
      break
    case "true-false":
      return !!submission
    case "order-correctly":
    case "match-columns":
    case "fill-in-missing": {
      let passed = true
      submission?.forEach((x, i) => {
        if (Number(x?.correct) !== i) {
          passed = false
        }
      })
      return passed
    }

    default:
      return true
  }
}

function initializer(initialState, { assessment, actions, forceAttempt = null }) {
  const assessmentActivity = actions?.find((x) => x.typeUid === assessment?.uid)
  const completed =
    assessmentActivity?.context?.submissions?.[assessmentActivity?.context?.submissions?.length - 1]?.passed &&
    assessmentActivity?.status === "completed"

  const allowedAttempts = assessment?.allowedAttempts ? Number(assessment?.allowedAttempts) : 999999

  let attemptNumber = 1

  if (forceAttempt !== null) {
    attemptNumber = forceAttempt
  } else if (assessmentActivity?.context?.submissions?.length >= Number(allowedAttempts)) {
    attemptNumber = assessmentActivity?.context?.submissions?.length

    if (
      Number(allowedAttempts || 0) + Number(assessmentActivity?.context?.additionalSubmissions?.length || 0) >
      Number(assessmentActivity?.context?.submissions?.length)
    ) {
      attemptNumber = assessmentActivity?.context?.submissions?.length + 1
    }
  } else if (assessmentActivity?.context?.submissions?.length) {
    attemptNumber = assessmentActivity?.context?.submissions?.length + 1
  } else {
    attemptNumber = 1
  }

  if (completed) {
    attemptNumber = assessmentActivity?.context?.submissions?.length - 1
  }

  const failedQuestions = []
  const prevSubmission = assessmentActivity?.context?.submissions?.[attemptNumber - 2]
  const submission = assessmentActivity?.context?.submissions?.[attemptNumber]

  if (!completed && assessment?.repeatFailedQuestions && prevSubmission) {
    Object.keys(prevSubmission?.submission).forEach((key, index) => {
      // console.log(`key: ${key} and index: ${index}::: `, prevSubmission?.submission?.[key])
      const qPass = determineQuestionPassed(assessment?.questions?.[index], prevSubmission?.submission?.[key])
      if (!qPass) {
        failedQuestions.push({
          ...assessment?.questions?.[index],
          // This will only exist for questions that are repeating
          questionNumberString: index + 1,
        })
      }
    })
  }

  const questions = failedQuestions?.length ? failedQuestions : assessment?.questions
  const total = determineTotal(questions)
  return {
    ...initialState,
    currIdx: 0,
    idx: 0,
    preview: completed,
    passed: completed,
    total,
    result: submission?.result,
    resultAsPercentage: submission?.resultAsPercentage,
    attempt: attemptNumber,
    failedQuestions,
    questions: failedQuestions?.length ? failedQuestions : assessment?.questions,
    isFailedQuestionsOnly: !!failedQuestions?.length,
    assessmentLimit: completed
      ? false
      : Number(allowedAttempts) + Number(assessmentActivity?.context?.additionalSubmissions?.length || 0) <=
        Number(assessmentActivity?.context?.submissions?.length),
    previwSubmission: completed ? submission?.submission : null,
  }
}

const assessmentReducer = (state, action) => {
  const { type, payload, field, block } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    case "next-attempt": {
      const newState = {
        ...state,
        idx: 0,
        loading: false,
        initialAnswers: {},
        currIdx: 0,
        preview: false,
        canProceed: false,
        resultAsPercentage: 0,
        passed: false,
        total: 0,
        result: 0,
        attempt: state?.attempt + 1,
        error: defaultErrorMessage,
        assessmentFailed: false,
        initialized: true,
        time: Date.now() + 1000 * 60 * state?.assessment?.timeLimit,
        // questions: state?.questions?.filter((q, i) => {
        //   if (!state?.failedQuestions?.length) return true
        //   return state?.failedQuestions?.includes(i)
        // }),
        failedQuestions: [],
      }
      const initializedData = initializer(newState, {
        assessment: state?.assessment,
        actions: [],
        forceAttempt: state?.attempt + 1,
      })
      return {
        ...initializedData,
        restartTimer: true,
      }
    }
    case "next": {
      if (block) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        idx: state?.idx + 1,
        currIdx: state?.idx + 1,
        canProceed: false,
        error: defaultErrorMessage,
      }
    }
    case "throwError": {
      return {
        ...state,
        canProceed: false,
        error: payload || defaultErrorMessage,
      }
    }
    case "completeAssessment": {
      return {
        ...state,
        ...payload,
        preview: true,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export function Assessments({ lessonQuery, enrolmentQuery, moduleQuery }) {
  const {
    data: { current: assessment },
  } = lessonQuery
  const { state: ctxState, dispatch: ctxDispatch } = useLesson()
  const { enrolmentUid, lessonUid, enrolmentType } = useParams()
  const activityQuery = useActivity({ uid: enrolmentUid })
  const queryClient = useQueryClient()
  const queryParam = useQueryParam()
  const isAdmin = queryParam.get("isAdmin")
  const isFacilitate = queryParam.get("facilitate")
  const { t } = useTranslation()

  const isPreviewing = useMemo(() => {
    if (isAdmin === "true" || isFacilitate === "true") return true
    const { activity } = enrolmentQuery?.data || { activity: [] }
    const lessonUid = lessonQuery?.data?.current?.uid || ""

    return (
      enrolmentQuery?.data?.currentState === "completed" ||
      activity.some(({ typeUid, status }) => typeUid === lessonUid && status === "completed")
    )
  }, [enrolmentQuery, lessonQuery])

  useEffect(() => {
    if (isPreviewing) dispatch({ type: "field", field: "preview", payload: true })
  }, [isPreviewing])

  const [state, dispatch] = useReducer(
    assessmentReducer,
    {
      idx: 0,
      loading: false,
      initialAnswers: {},
      assessmentUid: assessment?.uid,
      assessment,
      currIdx: 0,
      preview: false,
      canProceed: assessment?.questionsRequirePreviousAnswer,
      resultAsPercentage: 0,
      passed: false,
      total: 0,
      result: 0,
      attempt: 1,
      isFailedQuestionsOnly: false,
      error: defaultErrorMessage,
      failedQuestions: [],
      assessmentFailed: false,
      assessmentLimit: false,
      restartTimer: false,
      questions: assessment?.questions,
      time: Date.now() + 1000 * 60 * assessment?.timeLimit,
      previwSubmission: null,
    },
    (initial) =>
      initializer(initial, {
        assessment,
        actions: enrolmentQuery?.data?.activity,
      })
  )

  const onCountdownFinished = () => {
    if (!isPreviewing) {
      if (process.env.REACT_APP_ENV === "production") {
        submitAssessment(form.getFieldsValue())
      } else {
        alert(t("assessmentTimeLimitReacher"))
      }
    }
  }

  const [form] = Form.useForm()
  const endOfQsRef = useRef(null)
  const startOfReviewsRef = useRef(null)
  const personQuery = useGetPerson({ authenticated: true })
  const mutator = useEnrolmentActivtyMutation()

  useEffect(() => {
    dispatch({
      type: "field",
      field: "actionRunning",
      payload: false,
    })
  }, [])

  useEffect(() => {
    if (!state.preview) endOfQsRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [state.idx])
  useEffect(() => {
    if (state.preview) startOfReviewsRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [state.preview])

  const nextUrlByPathname = useMemo(() => {
    if (
      enrolmentQuery?.data &&
      !enrolmentQuery?.isLoading &&
      moduleQuery?.data &&
      !moduleQuery?.isLoading &&
      lessonQuery?.data &&
      !lessonQuery?.isLoading
    ) {
      if (!lessonQuery?.data?.next?.lessonUid || !lessonQuery?.data?.next?.moduleUid) return false

      const nextModuleIsTheSameAsCurrentModule = moduleQuery?.data?.uid === lessonQuery?.data?.next?.moduleUid

      if (nextModuleIsTheSameAsCurrentModule) return true

      if (enrolmentType.includes("course")) {
        const nextModule = enrolmentQuery?.data?.publication?.material?.find(
          (x) => x.uid === lessonQuery?.data?.next?.moduleUid
        )

        return nextModule?.learning?.find((x) => x.uid === lessonQuery?.data?.next?.lessonUid)?.kind === "assessment"
      } else {
        let nextModule

        enrolmentQuery?.data?.publication?.material?.forEach((section) => {
          section?.modules?.forEach((mod) => {
            if (mod.uid === lessonQuery?.data?.next?.moduleUid) {
              nextModule = mod
            }
          })
        })

        return nextModule?.learning?.find((x) => x.uid === lessonQuery?.data?.next?.lessonUid)?.kind === "assessment"
      }
    }
    return false
  }, [
    lessonQuery?.data,
    moduleQuery?.date,
    moduleQuery?.isLoading,
    !lessonQuery?.isLoading,
    enrolmentQuery?.data,
    !enrolmentQuery?.isLoading,
  ])

  console.log("nextUrlByPathname::: ", nextUrlByPathname)

  const submitAssessment = async (submission) => {
    const result = determineResult(state?.questions, submission)

    const resultAsPercentage = Math.round((result / state?.total) * 100)

    let resultToPreview = determineResult(state?.questions, submission)
    let resultAsPercentageToPreview = Math.round((result / state?.total) * 100)

    let passed =
      Number(resultAsPercentage) >= (assessment?.passScore === "NaN" ? 100 : Number(assessment?.passScore ?? 0))
    const submissionsHistory =
      activityQuery?.data?.find((x) => x.typeUid === assessment?.uid)?.context?.submissions ?? []

    if (state?.isFailedQuestionsOnly) {
      // take the first assessment attempt because it has all the original correct data ( total, correct no. of questions )
      // doing the below due to JS shallow copies
      const latestAttemptToBeMergedWithPreviousAttempt = JSON.parse(
        JSON.stringify(submissionsHistory?.[submissionsHistory?.length - 1])
      )

      state?.questions?.forEach((q, i) => {
        latestAttemptToBeMergedWithPreviousAttempt.submission[`q-${q.questionNumberString}`] =
          submission?.[`q-${i + 1}`]
      })
      const attemptResult = determineResult(
        assessment?.questions,
        latestAttemptToBeMergedWithPreviousAttempt.submission
      )
      const attemptResultAsPercentage = Math.round(
        (attemptResult / latestAttemptToBeMergedWithPreviousAttempt?.total) * 100
      )

      resultToPreview = attemptResult
      resultAsPercentageToPreview = attemptResultAsPercentage

      // if (attemptResultAsPercentage >= )
      passed = attemptResultAsPercentage >= (assessment?.passScore === "NaN" ? 100 : Number(assessment?.passScore ?? 0))

      submissionsHistory.push({
        ...latestAttemptToBeMergedWithPreviousAttempt,
        withFailedQuestions: true,
        percentage: Math.round((attemptResult / latestAttemptToBeMergedWithPreviousAttempt?.total) * 100),
        resultAsPercentage: attemptResultAsPercentage,
        result: attemptResult,
        attempt: state?.attempt,
        total: determineTotal(assessment?.questions),
        passed,
      })
    } else {
      submissionsHistory.push({
        attempt: state?.attempt,
        submission,
        passed,
        total: determineTotal(assessment?.questions),
        result,
        resultAsPercentage,
        percentage: resultAsPercentage,
      })
    }

    const assessmentActivity = {
      typeUid: assessment?.uid,
      type: "assessment",
      status: passed ? "completed" : "failed",
      context: {
        // ...submissionsHistory,
        ...activityQuery?.data?.find((x) => x.typeUid === assessment?.uid)?.context,
        submissions: submissionsHistory,
      },
    }

    if (!isPreviewing) {
      await trackAction({
        payload: assessmentActivity,
        mutator,
        publicationEnrolmentUid: enrolmentUid,
        dispatch: ctxDispatch,
      })
    }
    if (passed) {
      if (assessment?.revealResult) {
        ctxDispatch({
          type: "next",
          payload: {
            nextUrlByPathname: window.location.pathname,
            isPreviewing,
            queryClient,
            lessonUid,
            enrolmentUid,
            enrolmentType,
            currentModule: moduleQuery?.data,
            ...lessonQuery?.data?.next,
          },
        })
      } else {
        ctxDispatch({
          type: "next",
          payload: {
            nextUrlByPathname,
            isPreviewing,
            queryClient,
            lessonUid,
            enrolmentUid,
            enrolmentType,
            currentModule: moduleQuery?.data,
            ...lessonQuery?.data?.next,
          },
        })
      }
    } else {
      if (assessment?.allowedAttempts && state?.attempt >= assessment?.allowedAttempts) {
        dispatch({ type: "field", field: "assessmentLimit", payload: true })
      } else {
        dispatch({ type: "field", field: "assessmentFailed", payload: true })
      }
      dispatch({ type: "field", field: "result", payload: resultToPreview })
      dispatch({
        type: "field",
        field: "resultAsPercentage",
        payload: resultAsPercentageToPreview,
      })
      dispatch({ type: "field", field: "passed", payload: false })
    }
  }

  console.log("stuff", {
    state,
    assessment,
    ctxState,
    isPreviewing,
    enrolmentQuery: enrolmentQuery?.data,
    lessonQuery: lessonQuery?.data,
    moduleQuery: moduleQuery?.data,
  })

  return (
    <Layout style={{ background: "#f8f8f881" }}>
      {enrolmentQuery?.isLoading ||
        (enrolmentQuery.isFetching && (
          <div
            className="loading__spin"
            style={{
              position: "absolute",
              zIndex: "100",
              top: 0,
              right: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backdropFilter: "blur(6px) brightness(90%)",
            }}
          >
            <Spin tip={t("settingUpAssessment")} />
          </div>
        ))}
      <AssessmentFailed
        person={personQuery?.data}
        visible={state?.assessmentFailed}
        onClose={() => dispatch({ type: "field", field: "assessmentFailed", payload: false })}
        assessment={assessment}
        state={state}
        total={determineTotal(assessment?.questions)}
        dispatch={dispatch}
        form={form}
      />
      <AssessmentLimit
        person={personQuery?.data}
        visible={state?.assessmentLimit}
        onClose={() => dispatch({ type: "field", field: "assessmentLimit", payload: false })}
        assessment={assessment}
        state={state}
      />
      <header className="leasson__header" style={{ background: "#F9F9F9" }}>
        <div className="content">
          <Space size={0} direction="vertical">
            <p className="badge--meta"> {ctxState?.learning?.kind}</p>
            <Typography.Title style={{ margin: 0 }} level={3}>
              {lessonQuery?.data?.current?.title}
            </Typography.Title>
          </Space>
          <Space>
            {assessment?.timeLimit && !isPreviewing && <Countdown value={state?.time} onFinish={onCountdownFinished} />}
            <Link to="/">
              <Button type="link">{t("endLearning")}</Button>
            </Link>
          </Space>
        </div>
        <div className="progress">
          <div
            className="total"
            style={{
              width: `${state?.preview ? 100 : (state.idx / assessment?.questions?.length) * 100}%`,
            }}
          />
        </div>
      </header>
      <div
        xs={!state.preview ? 18 : 24}
        id="my-scroll-layout"
        className={`answer__panel ${state.preview ? "answer__panel--review" : ""}`}
      >
        {state.preview && (
          <>
            <div ref={startOfReviewsRef} />
            {!isFacilitate ||
              (isFacilitate !== "true" && (
                <>
                  <Hero>
                    <Hero.Slide img={moduleQuery?.data?.featureImageUrl}>
                      <Comment
                        author={<p style={{ color: "white", marginBottom: 0 }}>{t("Assessment")}</p>}
                        content={moduleQuery?.data?.title}
                      />
                      <Hero.Title level={2}>
                        {t("wellDone")}, {personQuery?.data?.firstName}
                      </Hero.Title>
                      <Hero.Description>{t("youAreDoingGreat")}</Hero.Description>
                    </Hero.Slide>
                  </Hero>
                  <div className="question__review">
                    <Typography.Title level={3} style={{ margin: 0 }}>
                      {t("assessmentResult")}
                    </Typography.Title>
                    <Row style={{ marginTop: "1.5rem" }} gutter={["16", "16"]}>
                      <Col style={{ marginBottom: 16 }} xs={24} sm={12} lg={8}>
                        <Card>
                          <Statistic
                            title={
                              <>
                                <DeploymentUnitOutlined size={12} /> Status
                              </>
                            }
                            value={state?.passed ? t("Passed") : t("Failed")}
                            valueStyle={{
                              color: state?.passed ? "#3f8600" : "#cf1322",
                            }}
                          />
                        </Card>
                      </Col>
                      <Col style={{ marginBottom: 16 }} xs={24} sm={12} lg={8}>
                        <Card>
                          <Statistic
                            title={
                              <>
                                <UnorderedListOutlined size={12} /> {t("answeredCorrectly")}
                              </>
                            }
                            value={state?.result}
                            valueStyle={{ color: "#335191" }}
                            suffix={
                              <Typography.Text style={{ fontSize: 12 }} disabled>
                                / {state?.total}
                              </Typography.Text>
                            }
                          />
                        </Card>
                      </Col>
                      <Col style={{ marginBottom: 16 }} xs={24} sm={12} lg={8}>
                        <Card>
                          <Statistic
                            title={
                              <>
                                <LineChartOutlined size={12} /> Result -{" "}
                                {assessment?.passScore &&
                                  assessment?.passScore > 0 &&
                                  `${t("Min")} (${assessment?.passScore}%)`}
                              </>
                            }
                            value={state?.resultAsPercentage}
                            valueStyle={{
                              color: state?.passed ? "#3f8600" : "#cf1322",
                            }}
                            suffix={
                              <Typography.Text style={{ fontSize: 12 }} disabled>
                                %
                              </Typography.Text>
                            }
                          />
                        </Card>
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                </>
              ))}
          </>
        )}
        <Form
          preserve
          form={form}
          initialValues={state.preview && state.previwSubmission ? state.previwSubmission : state.initialAnswers}
        >
          <fieldset disabled={state.preview} style={{ paddingBottom: state.preview ? "7rem" : 0 }}>
            {state?.questions
              ?.slice(0, state.preview ? state?.questions?.length : state.idx + 1)
              .map((question, qi) => (
                <React.Fragment key={question.uid}>
                  <div id={question.uid} className={`question ${state.preview ? "question--review" : ""}`}>
                    {question?.imageUrl && (
                      <img src={question?.imageUrl} alt="assessment img" className="question__image" />
                    )}
                    <div className="assessment__header">
                      <Space size={0}>
                        <p className="badge--meta badge--meta--noSpace">
                          {/* questionNumberString only exists if it is a repeated question. It gets set in the initializer */}
                          {t("Question")} {question?.questionNumberString ? question?.questionNumberString : qi + 1}{" "}
                          {t("of")} {assessment?.questions?.length}:{" "}
                        </p>
                        <Tag color="green">
                          {(() => {
                            switch (question.type) {
                              case "ture-false":
                                return t("trueFalse")
                              case "multiple-choice":
                                return t("multipleChoice")
                              case "order-correctly":
                                return t("orderCorrectly")
                              case "match-columns":
                                return t("matchColumns")
                              case "fill-in-missing":
                                return t("fillInMissing")

                              default:
                                return "Question"
                            }
                          })()}
                        </Tag>
                      </Space>
                      <Typography.Title style={{ margin: 0, fontWeight: "normal" }} level={3}>
                        <BoldText
                          text={
                            question?.type === "true-false" || question?.type === "multiple-choice"
                              ? question?.title
                              : question?.summary
                          }
                        />
                      </Typography.Title>
                    </div>
                    <Divider />
                    {(() => {
                      switch (question.type) {
                        case "multiple-choice":
                          return (
                            <MultipleChoice
                              dispatch={dispatch}
                              question={question}
                              questionIndex={qi}
                              form={form}
                              disabled={state.preview}
                              inReview={state.preview}
                              isFacilitate={isFacilitate}
                              required={assessment?.questionsRequirePreviousAnswer}
                            />
                          )
                        case "true-false":
                          return (
                            <TrueAndFalse
                              dispatch={dispatch}
                              question={question}
                              questionIndex={qi}
                              disabled={state.preview}
                              inReview={state.preview}
                              isFacilitate={isFacilitate}
                              required={assessment?.questionsRequirePreviousAnswer}
                            />
                          )
                        case "order-correctly":
                          return (
                            <OrderCorrectly
                              dispatch={dispatch}
                              question={question}
                              questionIndex={qi}
                              form={form}
                              disabled={state.preview}
                              inReview={state.preview}
                              isFacilitate={isFacilitate}
                              required={assessment?.questionsRequirePreviousAnswer}
                            />
                          )
                        case "match-columns":
                          return (
                            <MatchColumns
                              dispatch={dispatch}
                              question={question}
                              questionIndex={qi}
                              form={form}
                              disabled={state.preview}
                              inReview={state.preview}
                              isFacilitate={isFacilitate}
                              required={assessment?.questionsRequirePreviousAnswer}
                            />
                          )
                        case "fill-in-missing":
                          return (
                            <FillInMissing
                              dispatch={dispatch}
                              assessment={assessment}
                              question={question}
                              questionIndex={qi}
                              form={form}
                              disabled={state.preview}
                              inReview={state.preview}
                              isFacilitate={isFacilitate}
                              required={assessment?.questionsRequirePreviousAnswer}
                            />
                          )
                        default:
                          return <h2>Unsupported question type</h2>
                      }
                    })()}
                  </div>
                  {state.preview && qi < assessment?.questions?.length - 1 && <Divider />}
                </React.Fragment>
              ))}
          </fieldset>
        </Form>
        <div ref={endOfQsRef} />
      </div>
      <Footer style={{ background: "none" }} className="lesson__footer">
        <Space size={16}>
          {!state.preview ? (
            <>
              {state.idx > 0 && (
                <Card
                  onClick={() => {
                    dispatch({
                      type: "field",
                      field: "idx",
                      payload: state.idx - 1,
                    })
                    dispatch({
                      type: "field",
                      field: "currIdx",
                      payload: state.idx - 1,
                    })
                  }}
                  className="lesson__button lesson__button--prev"
                  bordered
                  hoverable
                >
                  <Space size={16}>
                    <Button shape="circle" className="icon" icon={<LeftOutlined />} />
                    <Space size={0} direction="vertical" align="start" className="button__content">
                      <Typography.Text className="title" strong>
                        {t("previousQuestion")}
                      </Typography.Text>
                    </Space>
                  </Space>
                </Card>
              )}
              <Card
                onClick={async () => {
                  if (ctxState?.actionRunning) {
                    message?.info("Saving progress...")
                    return
                  }
                  if (
                    state.idx < state.currIdx ||
                    !assessment?.questionsRequirePreviousAnswer ||
                    (form.getFieldValue(`q-${state.idx + 1}`) !== undefined &&
                      form.getFieldValue(`q-${state.idx + 1}`) !== null &&
                      state.canProceed)
                  ) {
                    const submission = form.getFieldsValue(true)
                    // const qSub = submission[`q-${state.idx + 1}`]
                    const question = state?.questions[state.idx]
                    const payload = {
                      typeUid: question.uid,
                      type: "question",
                      context: {
                        attempt: state?.attempt,
                        type: question.type,
                        submission: {
                          // This is for repeated question. When repeating question state.idx will not directly map to the index of the q since only repeated questions are mapped
                          key: `q-${question?.questionNumberString ? question?.questionNumberString : state.idx + 1}`,
                          data: submission[`q-${state.idx + 1}`],
                        },
                      },
                      status: "completed",
                    }
                    if (!isPreviewing) {
                      trackAction({
                        payload,
                        mutator,
                        publicationEnrolmentUid: enrolmentUid,
                        dispatch: ctxDispatch,
                      })
                    }

                    if (state.idx < state?.questions?.length - 1) {
                      dispatch({
                        type: "next",
                        payload: submission[`q-${state.idx + 1}`],
                      })
                    } else {
                      dispatch({
                        type: "next",
                        payload: submission[`q-${state.idx + 1}`],
                        block: true,
                      })
                      await submitAssessment(submission)
                    }
                  } else {
                    message.warn(state.error)
                  }
                }}
                className="lesson__button lesson__button--next"
                bordered
                hoverable
              >
                <Space size={16}>
                  <Button
                    // loading={ctxState.actionRunning}
                    type="primary"
                    shape="circle"
                    className="icon"
                    icon={<RightOutlined />}
                  />
                  <Space size={0} direction="vertical" align="start" className="button__content">
                    <Typography.Text className="title" strong>
                      {state.idx < state?.questions?.length - 1 ? t("nextQuestion") : t("submitAssessment")}
                    </Typography.Text>
                    <Typography.Text className="description" style={{ color: "white" }}>
                      {state.idx < state?.questions?.length - 1
                        ? `${t("Next")}: ${state?.questions[state.idx + 1].type
                            ?.replaceAll("-", " ")
                            ?.toSentenceCase()}`
                        : t("reviewResults")}
                    </Typography.Text>
                  </Space>
                </Space>
              </Card>
            </>
          ) : (
            <Card
              onClick={async () => {
                if (!isPreviewing) {
                  ctxDispatch({
                    type: "field",
                    field: "actionRunning",
                    payload: true,
                  })
                }
                ctxDispatch({
                  type: "next",
                  payload: {
                    nextUrlByPathname,
                    isPreviewing,
                    queryClient,
                    lessonUid,
                    enrolmentUid,
                    enrolmentType,
                    currentModule: moduleQuery?.data,
                    ...lessonQuery?.data?.next,
                  },
                })
              }}
              className="lesson__button lesson__button--next"
              bordered
              hoverable
            >
              <Space size={16}>
                <Button type="primary" shape="circle" className="icon" icon={<RightOutlined />} />
                <Space size={0} direction="vertical" align="start" className="button__content">
                  <Typography.Text className="title" strong>
                    {t("finishReview")}
                  </Typography.Text>
                  <Typography.Text className="description" style={{ color: "white" }}>
                    {t("andContinueLearning")}
                  </Typography.Text>
                </Space>
              </Space>
            </Card>
          )}
        </Space>
      </Footer>
    </Layout>
  )
}
