import { BellOutlined } from "@ant-design/icons"
import { Button, Drawer, List, Result } from "antd"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import db from "../firebase"
import { useGetPerson } from "../network/usePerson"
import { TrainingBookingCard } from "./cards/TrainingBookingCard"

export const TrainingRerquestDrawer = ({ visible, setVisible }) => {
  const [requests, setRequests] = useState([])
  const personQuery = useGetPerson({ authenticated: true })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    if (!personQuery.isLoading && personQuery.data) {
      // , where("assignedToUid", "==", personQuery.data?.uid)
      const q = query(
        collection(db, "trainingRequests"),
        where("assignedToUid", "==", personQuery.data?.uid),
        where("status", "!=", "accepted"),
        where("isNonExistantTraining", "==", true)
      )
      //   dX_EslAKy8BB
      const unsub = onSnapshot(q, (qs) => {
        const data = []
        qs.forEach((doc) => {
          data.push({ ...doc.data(), docId: doc.id })
        })
        setRequests(data)
        setLoading(false)
      })
      return () => unsub()
    }
  }, [personQuery.isLoading, personQuery.data])
  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className={`chat__yellow--icon ${requests.length && "alert"}`}
        type="link"
        icon={<BellOutlined />}
      />
      <Drawer
        width={window.innerWidth > 900 ? 800 : window.innerWidth}
        title="Training Requests"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        {requests.length > 0 ? (
          <List
            renderItem={(item) => (
              <TrainingBookingCard forceStatus="pending" isAdmin={true} item={item} person={personQuery?.data} />
            )}
            itemLayout="horizontal"
            dataSource={requests}
            size="large"
          />
        ) : (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button type="primary" onClick={() => setVisible(false)}>
                Close
              </Button>
            }
          />
        )}
      </Drawer>
    </>
  )
}
