import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const fetchActivity = async (uid, queryClient) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/learn/enrolments/${uid}/activity${
        window.localStorage.getItem("classroom") ? "?classroom=true" : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
        },
      }
    )
    .then((res) => res.data.data)
}
export const fetchEnrolment = async (uid, queryClient, urlParams) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/learn/enrolments/${uid}${urlParams && urlParams.length ? `?${urlParams}` : ""}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
        },
      }
    )
    .then(async (res) => {
      const activity =
        queryClient.getQueryData([uid, "activity"]) ??
        (await queryClient?.fetchQuery([uid, "activity"], () => fetchActivity(uid, queryClient)))
      return {
        ...res.data.data,
        activity,
      }
    })
}
export const useGetEnrolment = ({ uid, filters }) => {
  let params = {}
  if (window.localStorage.getItem("classroom")) {
    params = {
      ...params,
      classroom: true,
    }
  }

  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = {
          ...params,
          [key]: filters[key],
        }
      }
    })
  }

  const urlParams = new URLSearchParams(params).toString()
  const queryClient = useQueryClient()
  return useQuery([uid, filters], () => fetchEnrolment(uid, queryClient, urlParams), {
    enabled: !!uid,
    staleTime: 1000 * 60 * 1,
    onError: (error) => {
      message.error(`Failed to fetch course: ${error.response.data.message}`)
    },
  })
}
export const useActivity = ({ uid }) => {
  const queryClient = useQueryClient()
  return useQuery([uid, "activity"], () => fetchActivity(uid, queryClient), {
    enabled: !!uid,
    staleTime: 1000 * 60 * 1,
    onError: (error) => {
      message.error(`Failed to fetch activity: ${error?.response?.data?.message}`)
    },
  })
}
export const useGetEnrolmentModule = ({ enrolmentUid, moduleUid, sectionUid = null }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["module", moduleUid],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/learn/enrolments/${enrolmentUid}`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then(async (res) => {
          let module
          if (sectionUid) {
            module = res.data.data?.publication?.material
              ?.find((x) => x.uid === sectionUid)
              ?.modules?.find((y) => y.uid === moduleUid)
          } else {
            module = res.data.data.publication.material.find((y) => y.uid === moduleUid) || null
          }
          if (!module) {
            return new Error("Sorry, we could not find the module you were looking for")
          }
          const activity = await axios
            .get(`${process.env.REACT_APP_API_URL}/learn/enrolments/${enrolmentUid}/activity`, {
              headers: {
                Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
                "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
              },
            })
            .then((res) => res.data.data)
          return {
            ...module,
            currentState: res.data.data?.currentState,
            startDate: res.data.data?.startDate,
            publication: res.data.data?.publication,
            activity,
          }
        }),
    {
      initialData: () => {
        const initalEnrolement = queryClient.getQueryData(enrolmentUid)
        if (initalEnrolement) {
          let module
          if (sectionUid) {
            module = initalEnrolement?.publication?.material
              ?.find((x) => x.uid === sectionUid)
              ?.modules?.find((y) => y.uid === moduleUid)
          } else {
            module = initalEnrolement?.publication?.material?.find((y) => y.uid === moduleUid) || null
          }
          if (module) {
            return {
              ...module,
              currentState: initalEnrolement?.currentState,
              publication: initalEnrolement?.publication,
              activity: initalEnrolement?.activity,
            }
          }
        }
      },
      enabled: !!enrolmentUid,
      onError: (error) => {
        message.error(`Failed to fetch module: ${error.response.data.message}`)
      },
    }
  )
}
export const useEnrolmentMutation = (uid, skip = false) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .put(`${process.env.REACT_APP_API_URL}/learn/enrolments/${body.enrolmentUid}`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        if (skip) queryClient.invalidateQueries(uid)
      },
      onError: (error) => {
        message.error(`Failed to update enrolment: ${error.response.data.message}`)
      },
    }
  )
}
export const useCompleteEnrolmentMutation = (uid) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/learn/enrolments/${body.enrolmentUid}/complete`, null, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(uid)
      },
      onError: (error) => {
        message.error(`Failed to update enrolment: ${error.response.data.message}`)
      },
    }
  )
}
export const useDeleteEnrolmentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .delete(`${process.env.REACT_APP_API_URL}/learn/enrolments/${body.enrolmentUid}`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        message.success("Successfully unenrolled")
        queryClient.invalidateQueries("enrolments")
      },
      onError: (error) => {
        message.error(`Failed to unenroll: ${error.response.data.message}`)
      },
    }
  )
}
