import { Button, Checkbox, Divider, DatePicker, Drawer, Form, Input, Radio, Select, Space, Upload, message } from "antd"
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input"
import en from "world_countries_lists/data/countries/en/world.json"
import React, { useRef, useState } from "react"
import { useMedia } from "../network/useMedia"
import { useGetPerson } from "../network/usePerson"
import { addDoc, collection } from "firebase/firestore"
import dayjs from "dayjs"
import db from "../firebase"
import { nonHookUploader } from "../hooks/nonHookUploader"
import { InboxOutlined, PlusOutlined } from "@ant-design/icons"
import { useTrainingMutation } from "../network/useTraining"

export const HelpDrawer = ({ drawerFormType, visible, onClose, personQuery }) => {
  const [formLoading, setFormLoading] = useState(false)
  const [form] = Form.useForm()

  const trainingMutation = useTrainingMutation()

  const determineEmailAddress = (branchName) => {
    switch (branchName) {
      case "Goedehoop Colliery":
        return ["lxpbookinggoedehoop@thungela.com"]
      case "Mafube Colliery":
        return ["lxpbookingmafube@thungela.com"]
      case "Isibonelo Colliery":
        return ["lxpbookingisibonelo@thungela.com"]
      case "Greenside Colliery":
        return ["lxpbookinggreenside@thungela.com"]
      case "Zibulo Colliery":
        return ["lxpbookingzibulo@thungela.com"]
      case "Khwezela Colliery":
        return ["lxpbookingkhwezela@thungela.com"]
      case "Coal Shared Services":
        return ["lxpbookingsharedservices@thungela.com"]

      default:
        return ["trservicedesk@thungela.com"]
    }
  }

  const mediaMutator = useMedia()
  const onFinish = async (values) => {
    setFormLoading(true)
    try {
      const payload = {
        // to:
        //   drawerFormType === "training"
        //     ? determineEmailAddress(personQuery?.data?.companyPerson?.branch?.name)
        //     : ["trservicedesk@thungela.com"],
        cc: [personQuery?.data?.companyPerson?.email],
      }
      console.log("values::: ", values)
      switch (drawerFormType) {
        case "course":
          payload.message = {
            to: [process.env.NODE_ENV === "development" ? "dan@wixels.com" : "lxpbookingsharedservices@thungela.com"],
            subject: "Course Creation Request",
            html: `
                <div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'>
                  <img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' />
                  <p>
                  Hello Service Desk! Please see the training request from 
                  <strong>${personQuery?.data?.firstName} ${personQuery?.data?.lastName}</strong>
                  (${personQuery?.data?.companyPerson?.email})
                  </p>
                  <hr />
                  <h3>Course Creation Request</h3>
                  <br />
                  <p><strong>Name of Course: </strong>${values.courseName}</p>
                  <p><strong>Category: </strong>${values.category}</p>
                  <p><strong>Training Provider: </strong>${values.trainingProvider}</p>
                  <p><strong>Type of Training: </strong>${values.typeOfTraining}</p>
                </div>`,
          }
          break
        case "content":
          payload.message = {
            to: [process.env.NODE_ENV === "development" ? "dan@wixels.com" : "lxpbookingsharedservices@thungela.com"],
            subject: "Content Creation Request",
            html: `
                <div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'>
                  <img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' />
                  <p>
                  Hello Service Desk! Please see the training request from 
                  <strong>${personQuery?.data?.firstName} ${personQuery?.data?.lastName}</strong>
                  (${personQuery?.data?.companyPerson?.email})
                </p>
                  <hr />
                  <h3>Content Creation Request</h3>
                  <br />
                  <p><strong>Name of Course: </strong>${values.courseName}</p>
                  <p><strong>Category: </strong>${values.category}</p>
                  <p><strong>Training Provider: </strong>${values.trainingProvider}</p>
                  <p><strong>Type of Training: </strong>${values.typeOfTraining}</p>
                  <p><strong>Attachments: </strong>${
                    values?.docs?.fileList?.length > 0 ? values?.docs?.fileList?.map((x) => x?.imageUrl).join(", ") : []
                  }</p>
                </div>`,
          }
          break

        case "query":
          function generateHtmlString(data) {
            // Start with an opening div tag
            let htmlString = "<div>"

            // Iterate over the keys of the data object
            for (const key in data) {
              if (key !== "attachments") {
                // Add a p tag for each key-value pair
                htmlString += `<p>${key}: ${data[key]}</p>`
              } else {
                // Handle the attachments key separately
                if (data.attachments && data.attachments.fileList && data.attachments.fileList.length > 0) {
                  htmlString += "<p>Attachments</p><ul>"
                  // Iterate over the fileList and add each imageUrl as a list item
                  data.attachments.fileList.forEach((file) => {
                    htmlString += `<li><img src="${file.imageUrl}" alt="attachment"></li>`
                  })
                  // Close the ul tag
                  htmlString += "</ul>"
                }
              }
            }

            // Close the div tag
            htmlString += "</div>"

            return htmlString
          }
          console.log("values::: ", values)
          payload.message = {
            to: [process.env.NODE_ENV === "development" ? "dan@wixels.com" : "trservicedesk@thungela.com"],
            subject: "Support Query",
            html: generateHtmlString(values),
          }
          break
        default:
          break
      }

      if (drawerFormType === "training") {
        const payload = {
          companyUid: personQuery.data?.company?.uid,
          status: "pending",
          date: values?.dates?.map((date) => dayjs(date).format("YYYY-MM-DD")),
          training: values,
          isNonExistantTraining: true,
          members: [
            {
              imageUrl: personQuery?.data?.imageUrl,
              name: `${personQuery?.data?.firstName} ${personQuery?.data?.lastName}`,
              uid: personQuery?.data?.uid,
            },
          ],
        }
        await trainingMutation.mutateAsync({
          data: payload,
        })
      } else {
        await addDoc(collection(db, "notifications"), {
          ...payload,
          from:
            drawerFormType === "query"
              ? "Thungela Support Query <noreply@winwinza.com>"
              : "Thungela Course Creation Request <noreply@winwinza.com>",
          createdAt: new Date().toISOString(),
        })
      }

      form.resetFields()
      message.success("Successfully sent request")
      onClose()
      setFormLoading(false)
    } catch (error) {
      setFormLoading(false)
      message.error(error?.message)
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  const uploaderProps = {
    name: "file",
    multiple: true,
    accept: ".jpg, .jpeg, .png, .gif, .doc, .docx, .pdf",
    listType: "picture",
    customRequest: (req) => nonHookUploader({ ...req, mutator: mediaMutator }),
    maxCount: 4,
  }
  return (
    <Drawer
      visible={visible}
      placement="right"
      closable={true}
      onClose={(e) => {
        e.preventDefault()
        onClose()
      }}
      width={window.innerWidth > 700 ? 600 : window.innerWidth}
    >
      <Form
        form={form}
        initialValues={{
          diet: ["None"],
        }}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {(() => {
          switch (drawerFormType) {
            case "query":
              return (
                <>
                  <Form.Item
                    name="Query Type"
                    label="Query Type"
                    hasFeedback
                    rules={[{ required: true, message: "Please select add a query type!" }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Please select a query type"
                    >
                      <Option value="Access">Access</Option>
                      <Option value="Registration">Registration</Option>
                      <Option value="Completion">Completion</Option>
                      <Option value="Bookings">Bookings</Option>
                      <Option value="Reset Assessment"> Reset Assessment</Option>
                      <Option value="Other">Other - Please explain</Option>
                      <Option value="Internal Training">Internal Training</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"Message"}
                    label="Message"
                    rules={[{ required: true, message: "Please explain the issue you are encountering" }]}
                  >
                    <Input placeholder="Explain the issue you are encountering" />
                  </Form.Item>
                  <Form.Item name={"URL of Error"} label="URL of the page wher you are encountering the issue">
                    <Input placeholder="URL" />
                  </Form.Item>
                  <Form.Item
                    label="Please attach screenshots of the issue so that we can better assist you."
                    name="attachments"
                  >
                    <Upload.Dragger {...uploaderProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag files to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for Single or Bulk Upload. Strictly Prohibited: Uploading Company Data or Other Banned
                        Files.
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </>
              )
            case "course":
              return (
                <>
                  <TrainingCourseName />
                  <TrainingCategory />
                  <TrainingProvider />
                  <TrainingType />
                </>
              )
            case "content":
              return (
                <>
                  <TrainingCourseName />
                  <TrainingCategory />
                  <TrainingCategory />
                  <TrainingProvider />
                  <TrainingType />
                  <Form.Item
                    rules={[{ required: true, message: "Please add at least on file!" }]}
                    label="Add attachment / Upload files"
                    name="docs"
                  >
                    <Upload.Dragger {...uploaderProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag files to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for Single or Bulk Upload. Strictly Prohibited: Uploading Company Data or Other Banned
                        Files.
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </>
              )
            case "training":
              return (
                <>
                  <TrainingCourseName />
                  <TrainingCategory />
                  <TrainingProvider />
                  <Form.Item
                    rules={[{ required: true, message: "Please enter your provider contact person!" }]}
                    name="providerContactPerson"
                    label="Provider Contact Person"
                  >
                    <Input placeholder="Provider contact person name" />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please enter provider email address" }]}
                    name="providerEmail"
                    label="Email Address"
                  >
                    <Input placeholder="Provider email address" type="email" />
                  </Form.Item>
                  <ConfigProvider locale={en}>
                    <Form.Item
                      rules={[{ required: true, message: "Please enter provider mobile number" }]}
                      name="providerNumber"
                      label="Telephone Number/ Mobile Number"
                      initialValue={{
                        short: "za",
                      }}
                    >
                      <CountryPhoneInput size="small" style={{ width: "100%" }} />
                    </Form.Item>
                  </ConfigProvider>
                  <Form.Item
                    rules={[{ required: true, message: "Please select a training type!" }]}
                    name="traingRequestType"
                    label="Is the course internal or external"
                  >
                    <Radio.Group>
                      <Radio value="Internal">Internal</Radio>
                      <Radio value="External">External</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please input arrival and depature dates" }]}
                    name="dates"
                    label="Arrival & Depature Dates"
                  >
                    <DatePicker.RangePicker />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please select an accomodation" }]}
                    name="accomodation"
                    label="Do you need accommodation?"
                  >
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="accommodationType" label="Type of accommodation">
                    <Radio.Group>
                      <Radio value="Hotel">Hotel</Radio>
                      <Radio value="Guesthouse">Guesthouse</Radio>
                      <Radio value="Other">
                        <Form.Item name={"other"}>
                          <Input placeholder="Other" />
                        </Form.Item>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="accomodationWeekend" label="Do you need accommodation over the weekend?">
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please select your breakfast requirements" }]}
                    name="breakfast"
                    label="Do you require breakfast?"
                  >
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please select your dinner requirements" }]}
                    name="dinner"
                    label="Do you require dinner?"
                  >
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please select your lunch requirements" }]}
                    name="lunch"
                    label="Do you require a lunch pack?"
                  >
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please select your dieetary requirements" }]}
                    name="diet"
                    label="Dietary Requirements / Restrictions"
                  >
                    <Checkbox.Group
                      options={[
                        { label: "Vegetarian", value: "Vegetarian" },
                        { label: "Halal", value: "Halal" },
                        { label: "Vegan", value: "Vegan" },
                        { label: "None", value: "None" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please select your travel requirements" }]}
                    name="flight"
                    label="Do you require a flight?"
                  >
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please select your travel requirements" }]}
                    name="car"
                    label="Do you require a hire car?"
                  >
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )

            default:
              return null
          }
        })()}

        <Form.Item>
          <Button loading={formLoading} disabled={formLoading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

const TrainingCourseName = () => {
  return (
    <Form.Item
      name={"courseName"}
      label="Name of Course"
      rules={[{ required: true, message: "Please enter than name of the course" }]}
    >
      <Input placeholder="Enter the name of the course" />
    </Form.Item>
  )
}

const TrainingType = () => {
  const { Option } = Select
  return (
    <Form.Item
      name="typeOfTraining"
      label="Type of Training"
      hasFeedback
      rules={[{ required: true, message: "Please select your type of training!" }]}
    >
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
        placeholder="Please select a type of training"
      >
        <Option value="Qualification">Qualification</Option>
        <Option value="Short Course">Short Course</Option>
        <Option value="Core Business Skills">Core Business Skills</Option>
        <Option value="Engineering Core">Engineering Core</Option>
        <Option value="Leadership Training">Leadership Training</Option>
        <Option value="Mining Core">Mining Core</Option>
        <Option value="Online Training">Online Training</Option>
        <Option value="Other Trainings">Other Trainings</Option>
        <Option value="Skills Programme">Skills Programme</Option>
        <Option value="TMM License">TMM License</Option>
        <Option value="Conferences/Seminars/Workshops">Conferences/Seminars/Workshops</Option>
        <Option value="Internal Training">Internal Training</Option>
      </Select>
    </Form.Item>
  )
}

const TrainingCategory = () => {
  const { Option } = Select
  return (
    <Form.Item
      name="category"
      label="Category"
      hasFeedback
      rules={[{ required: true, message: "Please select your category!" }]}
    >
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
        placeholder="Please select a category"
      >
        <Option value="A">A</Option>
        <Option value="A2">A2</Option>
        <Option value="B">B</Option>
        <Option value="C">C</Option>
        <Option value="D">D</Option>
        <Option value="E">E</Option>
        <Option value="F">F</Option>
        <Option value="G">G</Option>
        <Option value="MST">MST</Option>
      </Select>
    </Form.Item>
  )
}

const TrainingProvider = () => {
  const [items, setItems] = useState([
    "Internal Training",
    "Will of Africa",
    "Ambu Training",
    "Prospen Africa",
    "Centurion Academy",
    "Simation",
    "CHABS",
    "Starcrow",
    "Beneficiation",
    "Thungela Central Training",
    "KBC",
    "WCC Assessments",
    "AA Technical Services",
    "SATS",
    "Impumelelo Adult Centre",
    "Loko Sewing School & Training Academy",
    "Ikage SD College",
    "ENJO Consultants",
    "Maccauvlei",
    "Action Man",
    "See the Glory",
    "Enterprises University of Pretoria",
    "Industrial Health Medical Screening",
    "Eduvos",
    "Radiation and Environmental Control Training Services ",
    "Digital Technology Services",
    "Yellow Technical Services",
    "Fire Fighting Training (FPSA)",
    "Drone Safety and Legal",
    "Our Gift of Light",
    "The Ulwazi Computer Training Centre",
    "Career Wise Empowerment Ventures",
    "STK Driving Academy",
    "Copper Coal Training",
    "Gesellschaft Fur Geratebau (GFG)",
    "Progressive Training Development and Projects",
    "Southern Business School",
    "Lifex Center Training",
    "Regent Business School",
    "Engineering Council of South Africa",
    "Killer Whale Aquatics Swimming Club",
    "Right 2 Skills",
    "North-West University",
    "Safety and training solutions",
    "Bokamoso Ba Lona",
    "Colliery Training Centre",
    "Green Solar Academy",
    "Intelligent Business Solutions",
    "NOSA",
    "Mines Rescue Services",
    "TNM Coporate Solutions",
    "Geological Society of South Africa",
    "Zikode",
    "Advanced Aerial",
    "Dwyka Mining Services",
    "Lindukukhanya Driving school",
    "Schauenburg systems",
    "Joy Global",
    "Semane",
    "LEHLAHLE TRADING (PTY) LTD",
    "Tshwane University of Technology ",
    "CMA training (pty)ltd",
    "W&C Consulting Services (Pty)Ltd.t/a CPRD Consulting Services",
    "Institute of Mine Surveyors",
    "UNISA",
    "Alpha",
    "University of Pretoria ",
    "Androit",
    "Veasey`s Engineering College",
    "2KO Africa ",
    "Mancosa (Pty) Ltd Management College Of Southern Africa",
    "Nkabinde Mp T/A Nfh Sound & Catering Services",
    "Am-Tech Raadgewende Ingenieurs(Pty) Ltd",
    "Ingenieurs(Pty) Ltd",
    "Amilak",
    "Lll & L Traning & Suppliers Cc Ta Proto Training",
    "Schraader",
    "Wits Commercial Enterprise Pty",
    "Get Smart",
    "Umoya",
    "Ln Holdings 88",
    "Air Conditioning",
    "Cement & Concrete",
    "Technology Risk Solutions",
    "Mineral Council",
    "Institute Of Accounting Science",
    "African Reptiles",
    "Bussynet",
    "Lexis Nexis",
    "Thibela",
    "NCC Environmental Services ",
    "Institute Of Accounting Science (Pty) Ltd",
    "Haggie",
    "Spiro",
    "IIE Rosebank College",
    "Engineering Institute Of Technology",
    "National Skills And Technical College",
    "Koolebale",
    "TR Safety Consultants",
    "ABB",
  ])
  const [providerName, setProviderName] = useState("")
  const inputRef = useRef(null)
  const onNameChange = (event) => {
    setProviderName(event.target.value)
  }
  const addProvider = (e) => {
    e.preventDefault()
    if (providerName && providerName !== "") {
      setItems([...items, providerName])
      setProviderName("")
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    } else {
      message.warn("Please enter a valid provider")
    }
  }
  return (
    <Form.Item
      name="trainingProvider"
      label="Training Provider"
      hasFeedback
      rules={[{ required: true, message: "Please select your training provider!" }]}
    >
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
        placeholder="Please select a category"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                placeholder="Please enter provider if not found in list"
                ref={inputRef}
                value={providerName}
                onChange={onNameChange}
              />
              <Button icon={<PlusOutlined />} onClick={addProvider}>
                Add Provider
              </Button>
            </Space>
          </>
        )}
        options={items.map((item) => ({
          label: item,
          value: item,
        }))}
      ></Select>
    </Form.Item>
  )
}
