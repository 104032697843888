import { CheckOutlined } from "@ant-design/icons"
import { Button, Layout, Pagination, Space, Spin, message } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/Page/AnnotationLayer.css"
import "react-pdf/dist/Page/TextLayer.css"
import { useParams } from "react-router-dom"
import { trackAction, useLesson } from "../../../../hooks/lesson-context"
import { useElementSize } from "../../../../hooks/useElementSize"
import { useEnrolmentActivtyMutation } from "../../../../network/useEnrolmentActivity"
import { useGet64Query } from "../../../../network/useMedia"
import { useTranslation } from "react-i18next"

export const Pdf = ({ lessonQuery, moduleQuery, isPreviewing, materialAsJSON }) => {
  const { enrolmentUid, lessonUid, enrolmentType } = useParams()
  const { state, dispatch } = useLesson()
  const pdfBase64 = useGet64Query({
    url: materialAsJSON.url,
  })
  const mutator = useEnrolmentActivtyMutation()
  const { t } = useTranslation()

  const { ref, width, height } = useElementSize()
  const pdfContainerSizes = useElementSize()

  const [numPages, setNumPages] = useState()
  const [currPage, setCurrPage] = useState(1)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  useEffect(() => {
    dispatch({ type: "field", field: "actionRunning", payload: false })
  }, [])

  const pdfPageProps = useMemo(() => {
    if (pdfContainerSizes.width < 789) {
      return {
        width,
      }
    }
    return {
      height,
    }
  }, [pdfContainerSizes.ref, pdfContainerSizes.width, width, height])
  return (
    <Layout style={{ background: "#f8f8f881" }}>
      <div ref={pdfContainerSizes.ref} className="pdf__container">
        <div className="header">
          {pdfContainerSizes.width >= 1020 && (
            <Pagination
              pageSize={1}
              defaultCurrent={currPage}
              current={currPage}
              total={numPages}
              onChange={(page) => {
                setCurrPage(page)
              }}
              showSizeChanger={false}
              size="small"
              showQuickJumper
            />
          )}
          <Space>
            <Button
              onClick={() => {
                if (currPage > 1) {
                  setCurrPage(currPage - 1)
                }
              }}
              type="secondary"
              size="small"
            >
              {t("Previous")}
            </Button>
            <Button
              loading={state?.actionRunning}
              disabled={state?.actionRunning}
              icon={<CheckOutlined />}
              onClick={async () => {
                if (currPage < numPages) {
                  setCurrPage(currPage + 1)
                } else {
                  if (!isPreviewing) {
                    await trackAction({
                      publicationEnrolmentUid: enrolmentUid,
                      dispatch,
                      mutator,
                      payload: {
                        typeUid: lessonUid,
                        type: "lesson",
                        status: "completed",
                      },
                    })
                  }
                  if (!state?.actionRunning) {
                    dispatch({
                      type: "next",
                      payload: {
                        isPreviewing,
                        lessonUid,
                        enrolmentUid,
                        enrolmentType,
                        currentModule: moduleQuery?.data,
                        ...lessonQuery?.data?.next,
                      },
                    })
                  } else {
                    message?.info("Saving progress...")
                  }
                }
              }}
              type="primary"
              size="small"
            >
              {currPage >= numPages ? t("Complete") : t("nextPage")}
            </Button>
          </Space>
        </div>

        {!pdfBase64.isLoading && pdfBase64.data && (
          <Document
            loading={""}
            onLoadError={() => {
              message.error("Error loading PDF")
            }}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            file={pdfBase64.data}
            className="pdf__renderer"
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
              ref={ref}
            >
              <Page className="pdf__page" {...pdfPageProps} pageNumber={currPage} />
            </div>
          </Document>
        )}
      </div>
    </Layout>
  )
}
